import { defineStore } from 'pinia';
import { Modals } from '@/js/quests/dashboard/types/enums';

interface ModalState {
    modals: {
        showAuth: boolean,
        showWalletConnect: boolean,
        showLeaving: boolean,
        showFeedback: boolean,
        showDeactivateAccount: boolean,
        showPasswordChange: boolean,
        showAddPassword: boolean,
        showEmailVerify: boolean,
        showForceEmailVerification: boolean,
        showStartedRound: boolean,
        showUnavailableRound: boolean,
        showLuckyDrawWinners: boolean,
        showLuckyDrawRestart: boolean,
        showRegisterBonus: boolean,
        showIntroduction: boolean,
        showHeartRecovery: boolean,
        showExamRetry: boolean,
        showNftMintSuccess: boolean,
        showBadge: boolean,
        showNftCertificate: boolean,
        showMetamaskAuthModal: boolean,
        showMetamaskAuthIntroModal: boolean,
        showMetamaskAndroidAuthIntroModal: boolean,
        showCoinbaseAuthModal: boolean,
        showCoinbaseAuthIntroModal: boolean,
        showCustomErrorModal: boolean,
        showTwitterAppBrowserModal: boolean,
        showReturnToMobileAppModal: boolean,
        showConnectedWalletModal: boolean,
        showConnectedSocialAccountModal: boolean,
        showUserWalletsModal: boolean,
        showUserSocialAccountsModal: boolean,
        showSocialAccountErrorModal: boolean,
        showGuildUnfollowModal: boolean,
        showReportModal: boolean,
        showFiltersModal: boolean,
        showGuildsSuggestionsModal: boolean,
        showBybitDealRewardClaimed: boolean,
        showBybitDeal: boolean,
        showUnlockStudioStep: boolean,
        showUnlockedStudio: boolean,
        showVideo: boolean,
        showInviteFriends: boolean,
        showHowToWithdrawModal: boolean,
        showSkipTaskModal: boolean,
        showDownloadAppModal: boolean,
        showRateBonusModal: boolean,
        showVisitSiteBonusModal: boolean,
        showBonusCongratulationsModal: boolean,
        showBrowserNotificationsModal: boolean,
        showSeasonWinnersModal: boolean,
        showTangibleRewardClaim: boolean,
        showExtensionConnectedModal: boolean,
        showDeleteGuildConfirmationModal: boolean,
    },
    showExitAnimation: boolean,
    isOnboarding: boolean,
    modalPromiseResolver: ((value?: unknown) => void) | null;
}

type ModalKeys = keyof ModalState['modals'];

export const useModalStore = defineStore({
    id: 'modalStore',
    state: (): ModalState => ({
        modals: {
            showAuth: false,
            showWalletConnect: false,
            showLeaving: false,
            showFeedback: false,
            showDeactivateAccount: false,
            showPasswordChange: false,
            showAddPassword: false,
            showEmailVerify: false,
            showForceEmailVerification: false,
            showStartedRound: false,
            showUnavailableRound: false,
            showLuckyDrawWinners: false,
            showLuckyDrawRestart: false,
            showRegisterBonus: false,
            showIntroduction: false,
            showHeartRecovery: false,
            showExamRetry: false,
            showNftMintSuccess: false,
            showBadge: false,
            showNftCertificate: false,
            showMetamaskAuthModal: false,
            showMetamaskAuthIntroModal: false,
            showMetamaskAndroidAuthIntroModal: false,
            showCoinbaseAuthModal: false,
            showCoinbaseAuthIntroModal: false,
            showCustomErrorModal: false,
            showTwitterAppBrowserModal: false,
            showReturnToMobileAppModal: false,
            showConnectedWalletModal: false,
            showConnectedSocialAccountModal: false,
            showUserWalletsModal: false,
            showUserSocialAccountsModal: false,
            showSocialAccountErrorModal: false,
            showGuildUnfollowModal: false,
            showReportModal: false,
            showFiltersModal: false,
            showGuildsSuggestionsModal: false,
            showBybitDealRewardClaimed: false,
            showBybitDeal: false,
            showUnlockStudioStep: false,
            showUnlockedStudio: false,
            showVideo: false,
            showInviteFriends: false,
            showHowToWithdrawModal: false,
            showSkipTaskModal: false,
            showDownloadAppModal: false,
            showRateBonusModal: false,
            showVisitSiteBonusModal: false,
            showBonusCongratulationsModal: false,
            showBrowserNotificationsModal: false,
            showSeasonWinnersModal: false,
            showTangibleRewardClaim: false,
            showExtensionConnectedModal: false,
            showDeleteGuildConfirmationModal: false,
        },
        showExitAnimation: false,
        isOnboarding: false,
        modalPromiseResolver: null,
    }),
    actions: {
        hideAllModals(value: boolean) {
            if (!value) {
                return;
            }

            const modals = this.modals;
            // eslint-disable-next-line guard-for-in, no-restricted-syntax
            for (const modal in modals) {
                modals[modal] = false;
            }
        },
        setModalVisibility(value: boolean, modalName: ModalKeys, hideOtherModals = true, timeout = 500) {
            this.hideAllModals(hideOtherModals);

            if (!value) {
                this.showExitAnimation = true;
                setTimeout(() => {
                    this.modals[modalName] = false;
                    this.showExitAnimation = false;
                }, timeout);
            } else {
                this.modals[modalName] = value;
            }
        },
        setAuthModal(value: boolean) {
            // IMPORTANT: Do not use hideAllModals - issue #3952
            this.modals.showAuth = value;
        },
        setWalletConnectModal(value: boolean, showAnimation = true) {
            if (showAnimation) {
                this.setModalVisibility(value, Modals.WALLET_CONNECT);
            } else {
                this.modals.showWalletConnect = value;
            }
        },
        setLeavingModal(value: boolean) {
            this.setModalVisibility(value, Modals.LEAVING);
        },
        setFeedbackModal(value: boolean, showAnimation = true) {
            if (showAnimation) {
                this.setModalVisibility(value, Modals.FEEDBACK);
            } else {
                this.modals.showFeedback = value;
            }
        },
        setDeactivateAccountModal(value: boolean) {
            this.setModalVisibility(value, Modals.DEACTIVATE_ACCOUNT);
        },
        setPasswordChangeModal(value: boolean) {
            this.setModalVisibility(value, Modals.PASSWORD_CHANGE);
        },
        setAddPasswordModal(value: boolean) {
            this.setModalVisibility(value, Modals.ADD_PASSWORD);
        },
        setEmailVerifyModal(value: boolean) {
            this.hideAllModals(value);
            this.modals.showEmailVerify = value;
        },
        setForceEmailVerificationModal(value: boolean) {
            this.setModalVisibility(value, Modals.FORCE_EMAIL_VERIFICATION);
        },
        setStartedRoundModal(value: boolean) {
            this.setModalVisibility(value, Modals.STARTED_ROUND);
        },
        setUnavailableRoundModal(value: boolean) {
            this.setModalVisibility(value, Modals.UNAVAILABLE_ROUND);
        },
        setLuckyDrawWinnersModal(value: boolean) {
            this.setModalVisibility(value, Modals.LUCKY_DRAW_WINNERS);
        },
        setLuckyDrawRestartModal(value: boolean) {
            this.setModalVisibility(value, Modals.LUCKY_DRAW_RESTART);
        },
        setExamRetryModal(value: boolean) {
            this.setModalVisibility(value, Modals.EXAM_RETRY);
        },
        setRegisterBonusModal(value: boolean) {
            this.setModalVisibility(value, Modals.REGISTER_BONUS);
        },
        setIntroductionModal(value: boolean) {
            this.setModalVisibility(value, Modals.INTRODUCTION);
        },
        setHeartRecoveryModal(value: boolean) {
            this.setModalVisibility(value, Modals.HEART_RECOVERY);
        },
        setBadgeModal(value: boolean) {
            this.setModalVisibility(value, Modals.BADGE);
        },
        setNftCertificateModal(value: boolean) {
            this.setModalVisibility(value, Modals.NFT_CERTIFICATE);
        },
        setNftMintSuccessModal(value: boolean) {
            this.setModalVisibility(value, Modals.NFT_MINT_SUCCESS);
        },
        setCustomErrorModal(value: boolean) {
            this.setModalVisibility(value, Modals.CUSTOM_ERROR);
        },
        setMetamaskAuthModal(value: boolean) {
            this.hideAllModals(value);
            this.modals.showMetamaskAuthModal = value;
        },
        setMetamaskAuthIntroModal(value: boolean) {
            // IMPORTANT: Do not use hideAllModals here
            this.modals.showMetamaskAuthIntroModal = value;
        },
        setMetamaskAndroidAuthIntroModal(value: boolean) {
            // IMPORTANT: Do not use hideAllModals here
            this.modals.showMetamaskAndroidAuthIntroModal = value;
        },
        setCoinbaseAuthModal(value: boolean) {
            this.hideAllModals(value);
            this.modals.showCoinbaseAuthModal = value;
        },
        setCoinbaseAuthIntroModal(value: boolean) {
            // IMPORTANT: Do not use hideAllModals here
            this.modals.showCoinbaseAuthIntroModal = value;
        },
        setTwitterAppBrowserModal(value: boolean) {
            this.hideAllModals(value);
            this.modals.showTwitterAppBrowserModal = value;
        },
        setReturnToMobileAppModal(value: boolean) {
            this.modals.showReturnToMobileAppModal = value;

            if (!value && this.modalPromiseResolver) {
                this.modalPromiseResolver();
                this.modalPromiseResolver = null;
            }
        },
        async waitForReturnToMobileAppModalClosure() {
            if (!this.modals.showReturnToMobileAppModal) {
                return Promise.resolve();
            }

            return new Promise((resolve) => {
                this.modalPromiseResolver = resolve;
            });
        },
        setConnectedWalletModal(value: boolean) {
            // IMPORTANT: Do not use hideAllModals here
            this.modals.showConnectedWalletModal = value;
        },
        setConnectedSocialAccountModal(value: boolean) {
            // IMPORTANT: Do not use hideAllModals here
            this.modals.showConnectedSocialAccountModal = value;
        },
        setUserWalletsModal(value: boolean) {
            this.hideAllModals(value);
            this.modals.showUserWalletsModal = value;
        },
        setUserSocialAccountsModal(value: boolean) {
            this.hideAllModals(value);
            this.modals.showUserSocialAccountsModal = value;
        },
        setSocialAccountErrorModal(value: boolean) {
            this.modals[Modals.SOCIAL_ACCOUNT_ERROR_MODAL] = value;
        },
        setGuildUnfollowModal(value: boolean) {
            this.modals.showGuildUnfollowModal = value;
        },
        setReportModal(value: boolean) {
            this.setModalVisibility(value, Modals.REPORT_MODAL);
        },
        setFiltersModal(value: boolean) {
            this.setModalVisibility(value, Modals.FILTERS_MODAL);
        },
        setGuildsSuggestionsModal(value: boolean) {
            this.setModalVisibility(value, Modals.GUILDS_SUGGESTIONS_MODAL);
        },
        setBybitDealRewardClaimedModal(value: boolean) {
            this.setModalVisibility(value, Modals.BYBIT_DEAL_REWARD_CLAIMED_MODAL);
        },
        setBybitDealModal(value: boolean) {
            this.setModalVisibility(value, Modals.BYBIT_DEAL_MODAL);
        },
        setUnlockStudioStepModal(value: boolean) {
            this.modals.showUnlockStudioStep = value;
        },
        setUnlockedStudioModal(value: boolean) {
            this.setModalVisibility(value, Modals.UNLOCKED_STUDIO_MODAL);
        },
        setVideoModal(value: boolean) {
            this.setModalVisibility(value, Modals.VIDEO_MODAL);
        },
        setInviteFriends(value: boolean) {
            this.modals.showInviteFriends = value;
        },
        setHowToWithdrawModal(value: boolean) {
            this.modals.showHowToWithdrawModal = value;
        },
        setSkipTaskModal(value: boolean) {
            this.modals.showSkipTaskModal = value;
        },
        setDownloadAppModal(value: boolean, isOnboarding = false) {
            this.isOnboarding = isOnboarding;
            this.modals.showDownloadAppModal = value;
        },
        setBrowserNotificationsModal(value: boolean) {
            this.setModalVisibility(value, Modals.BROWSER_NOTIFICATIONS);
        },
        setRateBonusModal(value: boolean) {
            this.modals.showRateBonusModal = value;
        },
        setVisitSiteBonusModal(value: boolean) {
            this.modals.showVisitSiteBonusModal = value;
        },
        setBonusCongratulationsModal(value: boolean) {
            this.modals.showBonusCongratulationsModal = value;
        },
        setSeasonWinnersModal(value: boolean) {
            this.setModalVisibility(value, Modals.SEASON_WINNERS);
        },
        setTangibleRewardClaimModal(value: boolean) {
            this.setModalVisibility(value, Modals.TANGIBLE_REWARD_CLAIM);
        },
        setExtensionConnectedModal(value: boolean) {
            this.setModalVisibility(value, Modals.EXTENSION_CONNECTED_MODAL);
        },
        setDeleteGuildConfirmationModal(value: boolean) {
            this.setModalVisibility(value, Modals.DELETE_GUILD_CONFIRMATION_MODAL);
        },
    },
});
